'use strict'

const localForage = require('localforage')

export const userStore = localForage.createInstance({
   name: 'userStore',
   version: 1, // Local Forage requires that this be a number, not a string. Only update this if there's a reason to.
})

export const stopPatchRequestsPendingSync = localForage.createInstance({
   name: 'stopPatchRequestsPendingSync',
   version: 1, // Local Forage requires that this be a number, not a string. Only update this if there's a reason to.
})
