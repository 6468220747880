'use strict'

const env = require('./env')
const {userStore, stopPatchRequestsPendingSync} = require('./stores')
const {format, utcToZonedTime, zonedTimeToUtc} = require('date-fns-tz')

function getPendingStopPatchRequests() {
   let stopPatchRequestsPendingSyncTemp = []
   return stopPatchRequestsPendingSync
      .iterate(function (value, key) {
         stopPatchRequestsPendingSyncTemp.push({
            url: key,
            payload: value,
         })
      })
      .then(() => {
         return stopPatchRequestsPendingSyncTemp
      })
}

Promise.all([
   userStore.getItem('userSession'),
   userStore.getItem('userRouteNames'),
   getPendingStopPatchRequests(),
]).then((response) => {
   if ('serviceWorker' in navigator) {
      return navigator.serviceWorker
         .register('/serviceWorker.js', {
            scope: '/',
         })
         .then((swRegistration) => {
            return init(response, swRegistration)
         })
   } else {
      console.error('Browser does not seem to support serviceWorkers :(')
      return init(response)
   }
})

function init(initPromisesResponse, swRegistration) {
   let userSession = initPromisesResponse[0]
   if (userSession) {
      // A `userSession` is only set if the user has the required permissions. We're not persisting this value but
      // Elm needs it (so we have to manually set it).
      userSession.hasRequiredPermissions = true
   }
   const userRouteNames = initPromisesResponse[1]
   const stopPatchRequestsPendingSyncForInit = initPromisesResponse[2]
   const elmApp = Elm.Main.init({
      flags: {
         appUrl: window.location.origin,
         apiUrl: env.apiUrl,
         apiUrlV2: env.apiUrlV2,
         appName: env.appName,
         appVersion: env.version,
         isOnline: window.navigator.onLine,
         isServiceworker: Boolean(swRegistration),
         userRouteNames: userRouteNames || [],
         userSession: userSession,
         stopPatchRequestsPendingSync: stopPatchRequestsPendingSyncForInit.length
            ? stopPatchRequestsPendingSyncForInit
            : null,
      },
      node: document.getElementById('app-root'),
   })

   // An initial check to set the online status.
   setOrUpdateOnlineStatus()

   //================================================================================
   // App update handling
   // Adapted from sources:
   // - https://deanhume.com/displaying-a-new-version-available-progressive-web-app/
   // - https://stackoverflow.com/a/51775987/1971662
   //================================================================================
   if (swRegistration) {
      let maybeNewWorker
      let isAppReloadingOnAppUpdate
      elmApp.ports.elmRouteChanged.subscribe(() => {
         // This fetches the worker's script URL, and if the new worker is not byte-by-byte identical to the current worker, it installs the new worker.
         swRegistration.update().then((updatedServiceWorkerRegistration) => {
            maybeNewWorker = updatedServiceWorkerRegistration.installing
            if (maybeNewWorker) {
               maybeNewWorker.addEventListener(
                  'statechange',
                  () => {
                     if (maybeNewWorker.state === 'installed' && navigator.serviceWorker.controller) {
                        // At this point the serviceworker has installed but is waiting to be activated.
                        // This triggers the "Update app now" button to show in Elm. Clicking this sends a message to the
                        // `clickedUpdateApp` port, which effectively activates the updated serviceworker and then refreshes the page.
                        elmApp.ports.appUpdateAvailable.send('Update!')
                     }
                  },
                  {once: true},
               )
            }
         })
      })
      elmApp.ports.clickedUpdateApp.subscribe(() => {
         // This handles reloading the app on update.
         // This is here to prevent the app reloading twice (i.e. when `window.location.reload()` is called elsewhere).
         navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (isAppReloadingOnAppUpdate) return
            isAppReloadingOnAppUpdate = true
            window.location.reload()
         })

         // This effectively activates the updated serviceworker.
         maybeNewWorker.postMessage({action: 'skipWaiting'})
      })
   }

   //================================================================================
   // Offline status handling
   //================================================================================

   window.addEventListener('online', () => setOrUpdateOnlineStatus())
   window.addEventListener('offline', () => setOrUpdateOnlineStatus())

   function setOrUpdateOnlineStatus() {
      return checkForNetwork('https://api-internal.azurestandard.com/routes?limit=1&swNoCache=true')
   }

   function sendOnlineStatusToElm(statusValue) {
      elmApp.ports.updateOnlineStatus.send(statusValue)
   }

   function checkForNetwork(url, retries = 3) {
      return fetch(url)
         .then((response) => {
            if (response.ok && response.status >= 200 && response.status < 304) {
               return sendOnlineStatusToElm(true)
            } else if (retries > 0) {
               return checkForNetwork(url, retries - 1)
            } else {
               return sendOnlineStatusToElm(false)
            }
         })
         .catch(() => {
            if (retries > 0) {
               return checkForNetwork(url, retries - 1)
            } else {
               return sendOnlineStatusToElm(false)
            }
         })
   }

   //================================================================================
   // Ports
   //================================================================================

   elmApp.ports.storeUsersDeliveryRoutes.subscribe((routes) => {
      userStore.setItem('userRouteNames', routes)
   })

   elmApp.ports.clickedReloadApp.subscribe(() => {
      window.location.reload()
   })

   elmApp.ports.storeUserSession.subscribe((userSession) => {
      if (!userSession.hasRequiredPermissions) return

      // We only want to store the user's email
      const userSessionToStore = {email: userSession.email}

      userStore.setItem('userSession', userSessionToStore).then(() => {
         // Reload the page in order to allow the service worker to begin caching requests.
         // An alternative method is to use `self.skipWaiting()` in the service worker, but
         // this is generally not recommended. See https://stackoverflow.com/a/40130378/1971662
         window.location.reload()
      })
   })

   elmApp.ports.removeUserSession.subscribe(() => {
      userStore.removeItem('userSession')
      userStore.removeItem('userRouteNames')
      // Remove service worker
      // This is to prevent stale caches and also making logging out an approach to troubleshooting.
      if (swRegistration) {
         navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (let registration of registrations) {
               registration.unregister()
            }
         })
         if ('caches' in window) {
            window.caches.keys().then((keyList) => {
               return Promise.all(
                  keyList.map(function (key) {
                     return window.caches.delete(key)
                  }),
               )
            })
         }
      }
   })

   elmApp.ports.copyTextToClipboard.subscribe((textToCopy) => {
      return navigator.clipboard.writeText(textToCopy).then(() => {
         elmApp.ports.copyToClipboardSucceeded.send('Success!')
      })
   })

   //================================================================================
   // Messages from service worker
   //================================================================================

   if (swRegistration) {
      navigator.serviceWorker.addEventListener('message', (event) => {
         if (event.data.type === 'updatePendingPatchRequests') {
            console.log(event)
            elmApp.ports.updatePatchRequestsPendingSync.send({
               url: event.data.url,
               payload: event.data.payload,
            })
         }
      })
   }

   //================================================================================
   // Custom elements
   //================================================================================

   window.customElements.define(
      'timezone-short',
      class extends HTMLElement {
         connectedCallback() {
            const zone = this.getAttribute('zone')
            const zonedDate = utcToZonedTime(this.getAttribute('src-time'), zone)
            this.innerHTML = format(zonedDate, 'z', {timeZone: zone})
         }
      },
   )

   window.customElements.define(
      'format-telephone',
      class extends HTMLElement {
         connectedCallback() {
            var number = this.getAttribute('number')
            var numberNoCC = number.replace('+1', '').trim()
            var areaCode = numberNoCC.slice(0, 3)
            var afterAreaCode = numberNoCC.slice(3).replace(/-/g, '')
            var afterAreaCodeFirstThree = afterAreaCode.slice(0, 3)
            var afterAreaCodeAfterFirstThree = afterAreaCode.slice(3)
            this.innerHTML = `(${areaCode}) ${afterAreaCodeFirstThree}-${afterAreaCodeAfterFirstThree}`
         }
      },
   )

   // Note: Using a "customized built-in element" for this would be simpler and cleaner, but this won't work for two reasons:
   // 1. No support on Mobile Safari (see https://developer.mozilla.org/en-US/docs/Web/API/CustomElementRegistry/define#Browser_compatibility)
   // 2. There seem to be some issues on the Elm side (like events not firing) when going this route
   window.customElements.define(
      'date-time-input',
      class extends HTMLElement {
         connectedCallback() {
            const now = new Date()
            const timezone = this.getAttribute('timezone')
            const maybeInitialValue = this.getAttribute('initial-value')

            function toLocalIso(date) {
               // Example return value: `2020-09-09T17:39`
               // Notes:
               // - This is for setting the value of `datetime-local` (so it does not include the timezone offset)
               // - This does not include seconds (since the values we're getting and updated from the API do not use seconds)
               // - We can't use timezone from the user's system here since the driver may be in a different timezone than the date being edited).
               if (typeof date === 'string') {
                  // Remove everything after the minutes from the ISO string
                  return date.slice(0, 16)
               } else if (typeof date === 'object') {
                  return format(utcToZonedTime(date, timezone), `yyyy-MM-dd'T'HH:mm`, {timeZone: timezone})
               }
            }

            function toUtcIso(localDatetime) {
               const utcDate = zonedTimeToUtc(localDatetime, timezone)
               return utcDate.toISOString()
            }

            let inputElement = document.createElement('input')
            inputElement.setAttribute('type', 'datetime-local')
            inputElement.setAttribute('value', maybeInitialValue ? toLocalIso(maybeInitialValue) : toLocalIso(now))
            inputElement.setAttribute('max', toLocalIso(now))
            inputElement.style['width'] = '100%'

            this.appendChild(inputElement)

            // This is for sending the value to Elm
            inputElement.addEventListener('change', () => {
               this.valueForElm = toUtcIso(inputElement.value)
               this.dispatchEvent(new CustomEvent('datetimeInputChanged'))
            })
         }
      },
   )
}
